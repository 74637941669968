import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import Booking from "../pages/booking.js";

import { AnimatePresence } from "framer-motion";
import { useScrollToTop } from "../utils/functions.js";

function AnimatedRoutes() {
  const location = useLocation();
  useScrollToTop();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.key}>
        {/* <Route path="/" element={<Home />} />
        <Route path="/flash" element={<Designs />} />
        <Route path="/gallery" element={<Gallery />} /> */}
        <Route path="/booking" element={<Booking />} />
        <Route path="*" element={<Navigate to="/booking" replace />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
