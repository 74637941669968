import backgroundImg from "../img/backgrounds/andyhtattoos-bg.jpg"
import { ImageWithBlurBackground } from "../utils/functions";
/*
  TODO:
*/

const BackgroundPic = () => {
  console.log("rendering video background");

  return (
    <div className="picture-background">
      <ImageWithBlurBackground img={{ img: backgroundImg}} />
      {/* <img src={backgroundImg} alt="background" loading="lazy"></img> */}
    </div>

  );
};

export default BackgroundPic;

