import { useRef, } from "react";
import { Link } from "react-router-dom";
import newLogo2 from "../img/logos/andy-h-logo-copy.png";

const NavbarJustLogo = () => {
  console.log("rendering navbar");
  const navRef = useRef();

  return (
    <header>
      <div className="container">
        <nav ref={navRef} className="text-shadow vertical-align">
          <Link to="/booking">
            <img src={newLogo2} className="logo-no-nav bounce-in-top" alt="logo" />
          </Link>
        </nav>
        <h3 className="text-shadow">Wild Heart Tattoo</h3>
        <h4 className="text-shadow">1142 Frankford Ave, Philadelphia, PA 19123</h4>
      </div>
    </header>
  );
};

export default NavbarJustLogo;

/* NOTES
  - responsive: https://www.youtube.com/watch?v=amf18mxNX18
*/
