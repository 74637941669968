import designs from "../data_structures/designs";
import tats from "../data_structures/tats";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

/*
  TODO: Change the way that isMobile checks for mobile device
*/

export const getNextTatIndex = (curr_index) => {
  const numTats = Object.keys(tats).length;

  const converted_curr_index = Number(curr_index);
  if (isNaN(converted_curr_index)) {
    throw new Error("curr_index cannot be converted to a number");
  }
  var nextTatIndex = (converted_curr_index + 1) % (numTats + 1);
  if (nextTatIndex === 0) {
    nextTatIndex = 1;
  }

  return String(nextTatIndex);
};

export const getPrevTatIndex = (curr_index) => {
  var numTats = Object.keys(tats).length;
  const converted_curr_index = Number(curr_index);
  if (isNaN(converted_curr_index)) {
    throw new Error("curr_index cannot be converted to a number");
  }

  var prevTatIndex = (converted_curr_index - 1) % (numTats + 1);
  if (prevTatIndex === 0) {
    prevTatIndex = numTats;
  }

  return String(prevTatIndex);
};

export const getNextDesignIndex = (curr_index) => {
  const numDesigns = Object.keys(designs).length;

  const converted_curr_index = Number(curr_index);
  if (isNaN(converted_curr_index)) {
    throw new Error("curr_index cannot be converted to a number");
  }
  var nextDesignIndex = (converted_curr_index + 1) % (numDesigns + 1);
  // Below will cause an issue if you have an index of 0
  if (nextDesignIndex === 0) {
    nextDesignIndex = 1;
  }

  return String(nextDesignIndex);
};

export const getPrevDesignIndex = (curr_index) => {
  var numDesigns = Object.keys(designs).length;
  const converted_curr_index = Number(curr_index);
  if (isNaN(converted_curr_index)) {
    throw new Error("curr_index cannot be converted to a number");
  }

  var prevDesignIndex = (converted_curr_index - 1) % (numDesigns + 1);
  if (prevDesignIndex === 0) {
    prevDesignIndex = numDesigns;
  }

  return String(prevDesignIndex);
};

export const isMobileDevice = () => {
  const userAgent = navigator.userAgent || window.opera;

  // Check for phones
  // TODO: use 3rd party library to make this more exhaustive
  if (/iPhone|iPod|Android.*Mobile|Windows Phone|BlackBerry/i.test(userAgent)) {
    return true;
  }

  // Check for tablets
  if (/iPad|Android|PlayBook|Nexus [0-9]/i.test(userAgent)) {
    return true;
  }

  return false;
};

export const isSmallScreen = () => {
  // Ensure 'window' is defined (for server-side rendering compatibility)
  if (typeof window !== "undefined") {
    return window.innerWidth <= 700;
  }
  return false; // Default value for server-side rendering
};

export function handleResize(setIsSmallScreen, dispatch, tat_img, ACTIONS) {
  const isScreenSmall = window.innerWidth <= 700;
  setIsSmallScreen(isScreenSmall);

  if (isScreenSmall && tat_img) {
    dispatch({ type: ACTIONS.SET_TAT_NULL, payload: {} });
  }
}

export function getImgByValue(dict, value) {
  const obj = dict.find((obj) => obj.value === Number(value));
  if (obj.img === null || undefined) {
    console.log("No image found");
  }
  return obj.img ? obj.img : null;
}

export function ImageWithBlurBackground({ img }) {
  // State to track if the image has loaded
  const [isLoaded, setIsLoaded] = useState(false);
  const imageRef = useRef(null); // Reference to the img element

  // This is basically the initial check, if the image has already loaded when component mounts, use loaded image
  useEffect(() => {
    if (imageRef.current && imageRef.current.complete) {
      setIsLoaded(true);
    }
  }, []);

  // Function to handle image load
  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  // Extract the file name from the imported image URL

  // TODO: add default background image
  const imageName = img.img.split("/").pop().split(".")[0];

  // Check if imageName contains "tattoo-"
  if (
    !imageName.includes("tattoo-") &&
    !imageName.includes("design-") &&
    !imageName.includes("poster") &&
    !imageName.includes("bg")
  ) {
    // Handle the error: You can either return a default image, an error message, or null
    return null;
  }

  // Assuming dynamic imports are supported and imageName is valid
  let backgroundImageUrl;
  try {
    backgroundImageUrl = require(`../img/tattoos/${imageName}-small.jpg`);
  } catch (error) {
    try {
      // If the first try fails, try to load from the 'designs' folder
      backgroundImageUrl = require(`../img/designs/${imageName}-small.jpg`);
    } catch (secondError) {
      try {
        // If the first try fails, try to load from the 'designs' folder
        backgroundImageUrl = require(`../img/backgrounds/${imageName}-small.jpg`);
      } catch (thirdError) {
        // Handle the case where the small image is not found
        // You can log the error, show a fallback image, or return an error message
        console.error("Small image not found:", error);
        return null;
      }
    }
  }

  return (
    <div
      className={`blur-load ${isLoaded ? "loaded" : ""}`}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      <img
        ref={imageRef}
        src={img.img}
        className={img.className}
        loading="lazy"
        alt={img.description}
        onLoad={handleImageLoad}
      />
    </div>
  );
}

export function ImageNoBlurBackground({ img }) {
  // Construct the URL for the small image

  return (
    <img
      src={img.img}
      className={img.className}
      loading="lazy"
      alt={img.description}
    />
  );
}

export const useScrollToTop = () => {
  const location = useLocation();
  const delay = 1000; // Delay in milliseconds

  useEffect(() => {
    if (location.pathname === "/booking") {
      const timer = setTimeout(() => {
        window.scrollTo(0, 0);
      }, delay);

      return () => clearTimeout(timer); // Clear the timeout if the component unmounts or location changes
    }
  }, [location]); // Dependency on location
};
