import { BrowserRouter} from "react-router-dom";

// pages & components
// import Navbar from "./components/navbar.js";
import NavbarJustLogo from "./components/navbar_just_logo.js";
// import BackgroundVideo from "./components/Background_Video.js";
import BackgroundPic from "./components/Background_Pic.js";
// import LogoBig from "./components/Logo_big.js";

import AnimatedRoutes from "./components/AnimatedRoutes.js";

function App() {


  return (
    <div className="App">
        {/* <BackgroundVideo /> */}
        <BackgroundPic />
        {/* <LogoBig /> */}
        <BrowserRouter>
          <NavbarJustLogo/>
          <div className="pages">
            <AnimatedRoutes />
          </div>
        </BrowserRouter>
    </div>
  );
}

export default App;


/*
TODO:
  - Well managed
    - Performance: Ensure that images are optimized for the web, so they load quickly.
    - Responsiveness: Test your website on various devices to ensure it looks and functions well on all screen sizes.
    - Code Cleanup: Periodically review and clean up your code to remove any unused variables, functions, or styles.
  - Feature development
    - Lazy loading
    - load more feature on iphone
*/
