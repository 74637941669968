import Icons from "../img/icons/icons";

const Footer = () => {
  console.log("rendering footer");

  return (
    <div className="footer">
      <div className="container">
        <div className="flex-horizontal-container">

          <a
            href="https://www.instagram.com/andyhtattoo/"
            target="_blank"
            aria-label="https://www.instagram.com/andyhtattoo/ (opens in a new tab)"
            rel="noreferrer"
          >
            <Icons.InstagramIcon />
          </a>

        </div>
        {/* <div >
        <h3 className="text-shadow">Philadelphia, PA</h3>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
