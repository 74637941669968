import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Icons from "../img/icons/icons";

export const BookingForm = () => {
  const form = useRef();
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState(false);
  // const [imageBase64, setImageBase64] = useState("");

  const userID = process.env.REACT_APP_EMAILJS_USER_ID;
  const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;

  const sendEmail = (e) => {
    e.preventDefault();

    // Set the Base64 string to a hidden input's value
    // const hiddenInput = document.createElement("input");
    // hiddenInput.type = "hidden";
    // hiddenInput.name = "image_base64";
    // hiddenInput.value = imageBase64;
    // form.current.appendChild(hiddenInput);

    emailjs
      .sendForm(serviceID, templateID, form.current, userID)
      .then(
        (result) => {
          console.log(result.text);
          console.log("email sent");
          setEmailSent(true);
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
          console.error("email failed to send");
          setEmailError(true);
        }
      )
      // .finally(() => {
      //   Remove the hidden input after sending
      //   form.current.removeChild(hiddenInput);
      // })
      ;
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       // Here you have the Base64 string
  //       const base64String = reader.result;
  //       console.log(base64String); // Log the Base64 string to the console
  //       setImageBase64(base64String); // Set the Base64 string to a state variable
  //     };
  //     reader.readAsDataURL(file); // Start reading the file as a Data URL
  //   }
  // };


  const formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, ""); // Remove non-numeric characters
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneInputChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    e.target.value = formattedPhoneNumber;
  };

  return (
    <div className="email-form-container">
      <form className="text-shadow" ref={form} onSubmit={sendEmail}>
        <label>
          name<span className="required"> *</span>
        </label>
        <input type="text" name="user_name" required />
        <label>
          phone number<span className="required"> *</span>
        </label>
        <input
          type="tel"
          name="user_phone"
          title="Format: 123-456-7890"
          pattern="\(\d{3}\) \d{3}-\d{4}"
          required
          onChange={handlePhoneInputChange}
        />
        <label>
          email <span className="required"> *</span>
        </label>
        <input type="email" name="user_email" required />
        {/* <label>instagram handle</label>
        <input type="instagram_handle" name="instagram_handle" /> */}
        <label>description (flash if booking flash)</label>
        <textarea maxLength="5000" name="description" placeholder="" />
        <label>size</label>
        <input type="text" name="size" />
        <label>placement</label>
        <input type="text" name="placement" />
        <label>questions / comments</label>
        <textarea maxLength="5000" name="questions" placeholder="" />
        {emailError && <div>Error sending email. Please try again.</div>}
        {emailSent && (
          <span
            className="submit-success"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            <Icons.GreenCheckmark /> <h3>Booking submitted!</h3>
          </span>
        )}
        {/* <label>Image Attachment</label>
        <input
          type="file"
          name="image"
          accept="image/*"
          onChange={handleFileChange}
        /> */}
        <input className="submit-button" type="submit" value="Send" />
      </form>
    </div>
  );
};

// Name, phone number, description of what you want, attachment for image?, size, placement, hopefully, image
